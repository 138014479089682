
import useMarketplaceChain from "hooks/useMarketplaceChain";
import {NextPage } from 'next'
import Banner from "./components/Banner";
import styled from 'styled-components'
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'

import Link from "next/link";
import { Button} from "../primitives";
import {useRef} from "react";
const SlideWrap = styled.div`
  //@apply mt-7;
`
const LandWrap = styled.div`
    margin-left: auto;
    margin-right:auto;
    padding_left:0.75rem;
    padding-right:0.75rem;
    max-width:1590px;
    color:#D7D8D8;
    display:grid;
    margin-top:116px;
    @media (max-width: 600px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @media (min-width: 900px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    
    @media (min-width: 1820px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        
    }
    @media (min-width: 1200px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`

const SalesWrap = styled.div`
    @media (max-width: 600px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @media (min-width: 900px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media (min-width: 1820px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));

    }
    @media (min-width: 1200px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`
const  H2 = styled.h2`
    font-size: 50px;
    font-weight: 800;
    line-height: 1.2;
    color: #D7D8D8;
    margin: 180px auto 66px;
    padding_left: 0.75rem;
    padding-right: 0.75rem;
    max-width: 1590px;

`
const H2O = styled.h2`
    margin-bottom: 66px;
    font-size: 50px;
    font-weight: 800;
    line-height: 1.2;
    color: #D7D8D8;
`
const BlockItem = styled.div`
   height: 60px;
`
const NFTBanner: NextPage = () => {
    const swiperRef = useRef(null)
    const { routePrefix } = useMarketplaceChain()




    return (
        <div style={{
            backgroundColor:"black"
        }}>
            <div id='bannerWrap'>
                <Banner/>
            </div>
            {/*<SlideWrap>*/}
            {/*    <Swiper*/}
            {/*        modules={[Autoplay]}*/}
            {/*        ref={swiperRef}*/}
            {/*        autoplay={{*/}
            {/*            delay: 3000,*/}
            {/*            disableOnInteraction: false,*/}
            {/*        }}*/}
            {/*        spaceBetween={10}*/}
            {/*        slidesPerView={3}*/}
            {/*        centeredSlides*/}
            {/*        resizeObserver*/}
            {/*        observeParents*/}
            {/*        observer*/}
            {/*        loop*/}
            {/*        breakpoints={*/}
            {/*            {*/}
            {/*                640: {*/}
            {/*                    slidesPerView: 6*/}
            {/*                },*/}
            {/*            }*/}
            {/*        }*/}
            {/*        style={{*/}
            {/*            width:'100%'*/}
            {/*        }} v-if="slideList.length">*/}
            {/*        {slideList.map((item, index) => (*/}
            {/*            <SwiperSlide key={index}>*/}
            {/*                <div className="aspect-1/1">*/}
            {/*                    <img src={item} style={{*/}
            {/*                        width:"100%",*/}
            {/*                        borderRadius:"0.75rem"*/}
            {/*                    }}/>*/}
            {/*                </div>*/}
            {/*            </SwiperSlide>*/}
            {/*        ))}*/}
            {/*    </Swiper>*/}
            {/*</SlideWrap>*/}
            {/*<LandWrap className="wrapper" id='landWrap'  >*/}
            {/*    <H2O className="h2" id='h2'>Our land...</H2O>*/}
            {/*    <div style={{*/}
            {/*        marginTop:"30px"*/}
            {/*    }}>*/}
            {/*        <p style={{*/}
            {/*            fontSize:"20px"*/}
            {/*        }}>*/}
            {/*            Every land in Virtual Valley is crafted by hand, with no automated generation involved. Our team puts meticulous care into each piece, ensuring it holds a unique story.*/}
            {/*        </p>*/}
            {/*        <div style={{*/}
            {/*            display:'flex',*/}
            {/*            flexWrap:"wrap",*/}
            {/*            marginTop:"32px",*/}
            {/*            fontWeight:"800"*/}
            {/*        }}>*/}
            {/*            <Link href={`/${routePrefix}/explore`} style={{*/}
            {/*                marginTop:"42px",*/}
            {/*                marginRight:"22px"*/}
            {/*            }}>*/}
            {/*                <Button*/}
            {/*                    css={{ flex: 1, justifyContent: 'center',paddingLeft:"$4",paddingRight:"$4"}}*/}
            {/*                    corners="rounded"*/}
            {/*                    type="button"*/}
            {/*                >*/}
            {/*                    Buy Now*/}
            {/*                </Button>*/}
            {/*                /!*<button data-type='primary' className='px-8'>Mint Now</button>*!/*/}
            {/*            </Link>*/}
            {/*            <Link href={`/${routePrefix}/collections/trending`} style={{*/}
            {/*                marginTop:"42px"*/}
            {/*            }}>*/}
            {/*                <Button*/}
            {/*                    css={{ flex: 1, justifyContent: 'center',paddingLeft:"$4",paddingRight:"$4"}}*/}
            {/*                    corners="rounded"*/}
            {/*                    type="button"*/}
            {/*                >*/}
            {/*                    View full collection*/}
            {/*                </Button>*/}
            {/*            </Link>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</LandWrap>*/}

            {/*<SalesWrap className='wrapper'>*/}
            {/*    <H2 className="h2" id='h2'>Largest Sales</H2>*/}
            {/*    <div style={*/}
            {/*        {*/}
            {/*            position:"relative"*/}
            {/*        }*/}
            {/*    }>*/}
            {/*        <Swiper*/}
            {/*            modules={[Navigation]}*/}
            {/*            spaceBetween={65}*/}
            {/*            slidesPerView={1}*/}
            {/*            breakpoints={*/}
            {/*                {*/}
            {/*                    640: {*/}
            {/*                        slidesPerView: 3,*/}
            {/*                    },*/}
            {/*                }*/}
            {/*            }*/}
            {/*            navigation={{*/}
            {/*                nextEl: '.swiper-button-next',*/}
            {/*                prevEl: '.swiper-button-prev'*/}
            {/*            }}*/}
            {/*           style={{*/}
            {/*               marginLeft: "auto",*/}
            {/*               marginRight:"auto",*/}
            {/*               paddingLeft:"0.75rem",*/}
            {/*               paddingRight:"0.75rem",*/}
            {/*               maxWidth:"1590px"*/}
            {/*           }}>*/}
            {/*            {goodsList.map((item, index) => (*/}
            {/*                <SwiperSlide key={index}>*/}
            {/*                    <div style={{*/}
            {/*                        display:"flex",*/}
            {/*                        flexDirection:"column",*/}
            {/*                        color:"#D7D8D8"*/}
            {/*                    }} >*/}
            {/*                        <img src={item.image} style={{width:"100%"}}  />*/}
            {/*                        <p style={{fontSize:"36px", fontWeight:"8000"}} >Virtual Valley {item.id}</p>*/}
            {/*                        <p style={{fontSize:"28px"}}>*/}
            {/*                            <span style={{fontWeight:"800"}}>{item.price}</span>*/}
            {/*                            Ξ</p>*/}
            {/*                    </div>*/}
            {/*                </SwiperSlide>*/}
            {/*            ))}*/}
            {/*        </Swiper>*/}
            {/*    </div>*/}
            {/*</SalesWrap>*/}
            {/*<BlockItem></BlockItem>      {/*<SlideWrap>*/}
            {/*    <Swiper*/}
            {/*        modules={[Autoplay]}*/}
            {/*        ref={swiperRef}*/}
            {/*        autoplay={{*/}
            {/*            delay: 3000,*/}
            {/*            disableOnInteraction: false,*/}
            {/*        }}*/}
            {/*        spaceBetween={10}*/}
            {/*        slidesPerView={3}*/}
            {/*        centeredSlides*/}
            {/*        resizeObserver*/}
            {/*        observeParents*/}
            {/*        observer*/}
            {/*        loop*/}
            {/*        breakpoints={*/}
            {/*            {*/}
            {/*                640: {*/}
            {/*                    slidesPerView: 6*/}
            {/*                },*/}
            {/*            }*/}
            {/*        }*/}
            {/*        style={{*/}
            {/*            width:'100%'*/}
            {/*        }} v-if="slideList.length">*/}
            {/*        {slideList.map((item, index) => (*/}
            {/*            <SwiperSlide key={index}>*/}
            {/*                <div className="aspect-1/1">*/}
            {/*                    <img src={item} style={{*/}
            {/*                        width:"100%",*/}
            {/*                        borderRadius:"0.75rem"*/}
            {/*                    }}/>*/}
            {/*                </div>*/}
            {/*            </SwiperSlide>*/}
            {/*        ))}*/}
            {/*    </Swiper>*/}
            {/*</SlideWrap>*/}
            {/*<LandWrap className="wrapper" id='landWrap'  >*/}
            {/*    <H2O className="h2" id='h2'>Our land...</H2O>*/}
            {/*    <div style={{*/}
            {/*        marginTop:"30px"*/}
            {/*    }}>*/}
            {/*        <p style={{*/}
            {/*            fontSize:"20px"*/}
            {/*        }}>*/}
            {/*            Every land in Virtual Valley is crafted by hand, with no automated generation involved. Our team puts meticulous care into each piece, ensuring it holds a unique story.*/}
            {/*        </p>*/}
            {/*        <div style={{*/}
            {/*            display:'flex',*/}
            {/*            flexWrap:"wrap",*/}
            {/*            marginTop:"32px",*/}
            {/*            fontWeight:"800"*/}
            {/*        }}>*/}
            {/*            <Link href={`/${routePrefix}/explore`} style={{*/}
            {/*                marginTop:"42px",*/}
            {/*                marginRight:"22px"*/}
            {/*            }}>*/}
            {/*                <Button*/}
            {/*                    css={{ flex: 1, justifyContent: 'center',paddingLeft:"$4",paddingRight:"$4"}}*/}
            {/*                    corners="rounded"*/}
            {/*                    type="button"*/}
            {/*                >*/}
            {/*                    Buy Now*/}
            {/*                </Button>*/}
            {/*                /!*<button data-type='primary' className='px-8'>Mint Now</button>*!/*/}
            {/*            </Link>*/}
            {/*            <Link href={`/${routePrefix}/collections/trending`} style={{*/}
            {/*                marginTop:"42px"*/}
            {/*            }}>*/}
            {/*                <Button*/}
            {/*                    css={{ flex: 1, justifyContent: 'center',paddingLeft:"$4",paddingRight:"$4"}}*/}
            {/*                    corners="rounded"*/}
            {/*                    type="button"*/}
            {/*                >*/}
            {/*                    View full collection*/}
            {/*                </Button>*/}
            {/*            </Link>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</LandWrap>*/}

            {/*<SalesWrap className='wrapper'>*/}
            {/*    <H2 className="h2" id='h2'>Largest Sales</H2>*/}
            {/*    <div style={*/}
            {/*        {*/}
            {/*            position:"relative"*/}
            {/*        }*/}
            {/*    }>*/}
            {/*        <Swiper*/}
            {/*            modules={[Navigation]}*/}
            {/*            spaceBetween={65}*/}
            {/*            slidesPerView={1}*/}
            {/*            breakpoints={*/}
            {/*                {*/}
            {/*                    640: {*/}
            {/*                        slidesPerView: 3,*/}
            {/*                    },*/}
            {/*                }*/}
            {/*            }*/}
            {/*            navigation={{*/}
            {/*                nextEl: '.swiper-button-next',*/}
            {/*                prevEl: '.swiper-button-prev'*/}
            {/*            }}*/}
            {/*           style={{*/}
            {/*               marginLeft: "auto",*/}
            {/*               marginRight:"auto",*/}
            {/*               paddingLeft:"0.75rem",*/}
            {/*               paddingRight:"0.75rem",*/}
            {/*               maxWidth:"1590px"*/}
            {/*           }}>*/}
            {/*            {goodsList.map((item, index) => (*/}
            {/*                <SwiperSlide key={index}>*/}
            {/*                    <div style={{*/}
            {/*                        display:"flex",*/}
            {/*                        flexDirection:"column",*/}
            {/*                        color:"#D7D8D8"*/}
            {/*                    }} >*/}
            {/*                        <img src={item.image} style={{width:"100%"}}  />*/}
            {/*                        <p style={{fontSize:"36px", fontWeight:"8000"}} >Virtual Valley {item.id}</p>*/}
            {/*                        <p style={{fontSize:"28px"}}>*/}
            {/*                            <span style={{fontWeight:"800"}}>{item.price}</span>*/}
            {/*                            Ξ</p>*/}
            {/*                    </div>*/}
            {/*                </SwiperSlide>*/}
            {/*            ))}*/}
            {/*        </Swiper>*/}
            {/*    </div>*/}
            {/*</SalesWrap>*/}
            {/*<BlockItem></BlockItem>*/}
        </div>
    )
}


export default NFTBanner